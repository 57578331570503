import {createContext, useContext, useEffect, useState} from 'react'
import {
  GetProductDetailsWeb_CachedQuery,
  GetProductDetailsWeb_CachedQueryVariables
} from '@data/__generated__/types.main'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {getProductDetailsCached} from '@page-components/Order/Products/ProductsContent/SelectProduct/Inner/productQueries.main'
import {useApolloQuery} from 'apollo-hooks'

export const ProductIdContext = createContext<{
  productId?: string
  setProductId: (productId?: string) => void
}>({
  setProductId: () => {}
})

export const useSelectedProductId = () => {
  const {productId} = useContext(ProductIdContext)

  return productId
}

/**
 * This allows us to open the product modal without waiting for the page to load static props
 * (when routing from /pedir to /pedir/:productId).
 * As both pages use the same root page, only client side navigation is needed.
 */
export const useSetSelectedProductId = () => {
  const {setProductId} = useContext(ProductIdContext)

  return setProductId
}

export const SelectedProductIdContextProvider = ({children, productId}) => {
  const [forcedProductId, forceProductId] = useState(productId)

  useEffect(() => {
    forceProductId(productId)
  }, [productId])

  return (
    <ProductIdContext.Provider
      value={{
        productId: forcedProductId === undefined ? productId : forcedProductId,
        setProductId: forceProductId
      }}>
      {children}
    </ProductIdContext.Provider>
  )
}

export type SelectedProductType = GetProductDetailsWeb_CachedQuery['product']

export const useSelectedProduct = () => {
  const productId = useSelectedProductId()
  const websiteId = useWebsiteId()

  const data = useApolloQuery<
    GetProductDetailsWeb_CachedQuery,
    GetProductDetailsWeb_CachedQueryVariables
  >({
    query: getProductDetailsCached,
    partial: true,
    fetchPolicy: 'cache-first',
    omit: !productId || isServerSide(),
    variables: {
      productId,
      websiteId,
      format: 'webp'
    }
  })

  if (!productId) return null

  return data?.product
}

import SuspenseLoading from '@components/SuspenseLoading'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import TrackOrderPageView from '@page-components/AnalyticsV2/tracking-components/TrackOrderPageView'
import NextOpenDateWrapper from '@page-components/Order/NextOpenDate'
import useLayout from '@page-components/Order/useLayout'
import Page from '@page-components/Page'
import dynamic from 'next/dynamic'
import {Suspense} from 'react'

import CreateAddressModal from './CreateAddressModal'
import Products from './Products'
import {SelectedProductIdContextProvider} from './useSelectedProduct'

export interface OrderProps {
  productId?: string
  websiteId?: string
  componentIdToSsrProps?: {[componentId: string]: any}
}

const LayoutV1 = dynamic(() => import('./layouts/LayoutV1'))
const LayoutV2 = dynamic(() => import('./layouts/LayoutV2'))
const LayoutMobile = dynamic(() => import('./layouts/LayoutMobile'))

const TrackPageView = dynamic(() => import('./TrackPageView'), {ssr: false})
const PageMeta = dynamic(() => import('@page-components/Order/PageMeta'), {ssr: false})
const getLayout = (layout: string) => {
  switch (layout) {
    case 'v1':
      return LayoutV1
    case 'v2':
      return LayoutV2
    case 'mobile':
      return LayoutMobile
    default:
      return LayoutV2
  }
}

function OrderV2Inner(props: OrderProps) {
  /**
   * Do not call hooks that suspend here, otherwise we will have a flicker effect
   */
  const {productId} = props
  const layout = useLayout()
  const Layout = getLayout(layout)

  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()

  return (
    <>
      <CreateAddressModal />
      <Page slug={['/pedir', '/order']} />
      <NextOpenDateWrapper />
      <Suspense fallback={<div className="h-20 w-full bg-red-500" />}>
        <Layout>
          <SelectedProductIdContextProvider productId={productId}>
            <Products />
          </SelectedProductIdContextProvider>
        </Layout>
      </Suspense>

      {!isAnalyticsV2Avail && <TrackPageView />}
      {isAnalyticsV2Avail && <TrackOrderPageView />}
      {productId ? null : <PageMeta />}
    </>
  )
}

export default function OrderV2(props: OrderProps) {
  return <SuspenseLoading>{<OrderV2Inner {...props} />}</SuspenseLoading>
}

import gql from 'graphql-tag'

export const getProductDetailsCached = gql`
  query getProductDetailsWeb_cached($productId: ID, $format: String, $websiteId: ID) {
    product(productId: $productId, websiteId: $websiteId) {
      _id
      name
      description
      altImg
      titleImg
      urlVideo
      informativeStamps
      promotions {
        _id
        promotionType
      }
      images {
        _id
        url
        imageLarge: resizedURL(width: 1200, height: 1200, format: $format)
      }
      categories {
        _id
        name
        internalName
      }
      maxPurchaseQuantity
      modifiers {
        _id
        name
        description
        min
        max
        optional
        isImageModifier
        options {
          _id
          name
          max
          requiresModifierOptionIds
          requiresAtLeastOneModifierOption
          image {
            _id
            url
            imageLarge: resizedURL(width: 300, height: 300, format: $format)
          }
        }
      }
    }
  }
`
export const getProductDetails = gql`
  query getProductDetailsNonCached(
    $productId: ID
    $menuId: ID
    $websiteId: ID
    $storeId: ID
    $deliverAt: Date
  ) {
    product(productId: $productId, websiteId: $websiteId) {
      _id
      isOutOfStock(storeId: $storeId, deliverAt: $deliverAt)
      availabilityAt(menuId: $menuId) {
        available
        basePrice
        finalPrice
        notAvailableMessage
        hideDiscountBadge
      }
      modifiers {
        _id
        options {
          _id
          requiresModifierOptionIds
          requiresAtLeastOneModifierOption
          availabilityAt(menuId: $menuId) {
            menuId
            price
            available
          }
          isOutOfStock(storeId: $storeId, deliverAt: $deliverAt)
        }
      }
    }
  }
`

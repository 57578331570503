import React, {Suspense} from 'react'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'

import NextOpenDate from './NextOpenDate.main'

function NextOpenDateWrapperInner(props: {
  className?: string
}) {
  const {storeId} = useUserPreferences()
  if (!storeId) return null

  return (
    <NextOpenDate {...props} />
  )
}

export default function NextOpenDateWrapper(props: {
  className?: string
}) {
  return (
    <Suspense fallback={null}>
      <NextOpenDateWrapperInner {...props} />
    </Suspense>
  )
}
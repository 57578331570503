import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3SchedulePreferences on Preference {
    timeLabel
    time
    dropOffType
    deliverAt
    store {
      minimumAnticipationDays
      availableScheduleDaysOptions {
        label
        value
      }
      schedule {
        itsOpenNow: itsAvailableAt(websiteId: $websiteId)
      }
      currentPreparationDuration
      currentDeliveryDuration
      availablePaymentTypes
    }
  }
`
